import { Link } from 'gatsby'
import React from 'react'

const Footer = () => {
	return (
		<footer className="bg-footerGray">
			<nav className="flex justify-between max-w-4xl mx-auto p-4 md:p-8 text-sm">
				{/* <p className="text-white">
					Everything ©{` `}
					<Link
						title="Back Home"
						className="font-bold no-underline text-white"
						to="/"
					>
						Anshuman Verma 2019
					</Link>
				</p> */}

				{/* <p>
					<a
						className="font-bold no-underline text-white"
						href="https://github.com/anshumanv"
					>
						GitHub
					</a>
				</p> */}
			</nav>
		</footer>
	)
}

export default Footer
