import PropTypes from 'prop-types'
import React from 'react'
import Footer from './footer'
import Header from './header'

function Layout({ children }) {
	return (
		<div className="flex flex-col font-sans bg-gray-900 min-h-screen text-white">
			<Header />

			<main className="flex flex-col flex-1 max-w-4xl mx-auto px-4 py-8 md:p-8 w-full">
				{children}
			</main>

			<Footer />
		</div>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
}

export default Layout
