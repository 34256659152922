import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'

function Header() {
	const [isExpanded, toggleExpansion] = useState(false)
	const { site } = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	return (
		<header className="bg-transparent">
			<div className="flex flex-wrap items-center justify-between max-w-4xl mx-auto p-4 md:p-8">
				<Link className="flex items-center no-underline text-white" to="/">
					<span className="font-bold text-xl tracking-tight uppercase font-noto">
						{site.siteMetadata.title}
					</span>
				</Link>

				{/* <button
					className="block md:hidden border border-white flex items-center px-3 py-2 rounded text-white"
					onClick={() => toggleExpansion(!isExpanded)}
				>
					<svg
						className="fill-current h-3 w-3"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
					>
						<title>Menu</title>
						<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
					</svg>
				</button> */}

				<nav
					className={`block md:block flex justify-around md:items-center w-full md:w-auto`}
				>
					{[
						{
							route: `/`,
							title: `Home`
						},
						{
							route: `/blog`,
							title: `Blog`
						},
						{
							route: `/work`,
							title: `Work`
						},
						{
							route: `/uses`,
							title: `Uses`
						},
						{
							route: `/about`,
							title: `About`
						}
					].map(link => (
						<Link
							className="inline-block md:inline-block justify-between border-solid hover:border-teal-500 border-b-2 border-gray-900 text-relaxed uppercase mt-4 md:mt-0 md:ml-6 no-underline font-noto text-white"
							key={link.title}
							title={link.title}
							to={link.route}
						>
							{link.title}
						</Link>
					))}
				</nav>
			</div>
		</header>
	)
}

export default Header
