import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import me from '../../images/me.png'

function SEO({
	description,
	lang,
	meta,
	keywords,
	title,
	url,
	type,
	seoImg,
	seoImgAlt,
	node,
	article
}) {
	const { site } = useStaticQuery(graphql`
		query DefaultSEOQuery {
			site {
				siteMetadata {
					title
					description
					authorTwitter
					siteUrl
					author
					headline
				}
			}
		}
	`)

	const defaultDescription = site.siteMetadata.description
	const defaultTitle = site.siteMetadata.title
	const metaDescription = description || defaultDescription
	const siteUrl = url || site.siteMetadata.siteUrl
	const headline = site.siteMetadata.headline
	const author = site.siteMetadata.author

	const schemaOrgWebPage = {
		'@context': 'http://schema.org',
		'@type': 'Person',
		url: siteUrl,
		sameAs: [
			'https://www.facebook.com/anshumanv12/',
			'https://twitter.com/Anshumaniac/',
			'https://www.linkedin.com/in/anshumanv12/'
		],
		headline,
		inLanguage: lang,
		mainEntityOfPage: siteUrl,
		description: defaultDescription,
		name: defaultTitle,
		author: {
			'@type': 'Person',
			name: author
		},
		copyrightHolder: {
			'@type': 'Person',
			name: author
		},
		copyrightYear: '2019',
		creator: {
			'@type': 'Person',
			name: author
		},
		publisher: {
			'@type': 'Person',
			name: author
		},
		datePublished: '2019-12-06T10:30:00+01:00',
		image: {
			'@type': 'ImageObject',
			url: `https://github.com/anshumanv.png`
		}
	}

	// Initial breadcrumb list

	const itemListElement = [
		{
			'@type': 'ListItem',
			item: {
				'@id': siteUrl,
				name: 'Homepage'
			},
			position: 1
		}
	]

	let schemaArticle = null

	if (type) {
		schemaArticle = {
			'@context': 'http://schema.org',
			'@type': 'Article',
			sameAs: [
				'https://www.facebook.com/anshumanv12/',
				'https://twitter.com/Anshumaniac',
				'https://www.linkedin.com/in/anshumanv12/'
			],
			author: {
				'@type': 'Person',
				name: seoImgAlt
			},
			copyrightHolder: {
				'@type': 'Person',
				name: author
			},
			copyrightYear: '2019',
			creator: {
				'@type': 'Person',
				name: author
			},
			publisher: {
				'@type': 'Person',
				name: author,
				logo: {
					'@type': 'ImageObject',
					url: 'https://github.com/anshumanv.png'
				}
			},
			datePublished: node.published_on,
			description: metaDescription,
			headline: title,
			inLanguage: lang,
			url: url,
			name: title,
			image: {
				'@type': 'ImageObject',
				url: seoImg
			},
			mainEntityOfPage: url
		}
		// Push current blogpost into breadcrumb list
		itemListElement.push({
			'@type': 'ListItem',
			item: {
				'@id': url,
				name: title
			},
			position: 2
		})
	}

	const breadcrumb = {
		'@context': 'http://schema.org',
		'@type': 'BreadcrumbList',
		description: 'List',
		name: 'Breadcrumbs',
		itemListElement
	}

	return (
		<Helmet
			htmlAttributes={{
				lang
			}}
			meta={[
				{
					name: `description`,
					content: metaDescription
				},
				{
					name: `theme-color`,
					content: '#282c35'
				},
				{
					name: `image`,
					content: seoImg
				},
				{
					name: `theme-color`,
					content: '#282c35'
				},
				{
					property: `og:title`,
					content: title
				},
				{
					property: `og:locale`,
					content: 'en'
				},
				{
					property: `og:description`,
					content: metaDescription
				},
				{
					property: `og:type`,
					content: `website`
				},
				{
					property: `og:site_name`,
					content: `anshumanv.dev`
				},
				{
					property: `og:url`,
					content: siteUrl
				},
				{
					property: `og:image`,
					content: seoImg
				},
				{
					property: `og:image:alt`,
					content: seoImgAlt
				},
				{
					name: `twitter:card`,
					content: `summary`
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.authorTwitter
				},
				{
					name: `twitter:title`,
					content: title
				},
				{
					name: `twitter:card`,
					content: type
				},
				{
					name: `twitter:image`,
					content: seoImg
				},
				{
					name: `twitter:image:alt`,
					content: seoImgAlt
				},
				{
					name: `twitter:description`,
					content: metaDescription
				}
			]
				.concat(
					keywords.length > 0
						? {
								name: `keywords`,
								content: keywords.join(`, `)
						  }
						: []
				)
				.concat(meta)}
			title={title}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
		>
			{!article && (
				<script type="application/ld+json">
					{JSON.stringify(schemaOrgWebPage)}
				</script>
			)}
			{article && (
				<script type="application/ld+json">
					{JSON.stringify(schemaArticle)}
				</script>
			)}
			<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
		</Helmet>
	)
}

SEO.defaultProps = {
	lang: `en`,
	keywords: [],
	meta: [],
	seoImg: me,
	seoImgAlt: 'Anshuman Verma',
	node: null
}

SEO.propTypes = {
	description: PropTypes.string,
	keywords: PropTypes.arrayOf(PropTypes.string),
	lang: PropTypes.string,
	meta: PropTypes.array,
	title: PropTypes.string.isRequired
}

export default SEO
